export default class test {
  constructor() {
    this.input = {
      departments: [],
      name: "",
      phone: "",
      roleId: 0,
    };
    this.body = [];
  }
  setInput(mode, data) {
    if (mode === "Сохранить") {
      this.input.id = data.id;
      this.input.name = data.username;
      this.input.phone = data.phone;
      this.input.roleId = data.role.id;
      this.input.departments = data.departments.map(function (x) {
        return x.id;
      });
      data.fuelCard && (this.input.fuelCard = data.fuelCard);
      data.contractType && (this.input.contractTypeId = data.contractType.id);
      this.input.timeTable = data.timeTable ? data.timeTable.id : null;
    }
    return this.input;
  }
  setBody(data, deleted) {
    if (data) {
      for (let i = 0; i < data.length; i++) {
        this.body.push({
          fields: [
            {
              id: 1,
              name: data[i].id,
              type: "string",
            },
            {
              id: 2,
              name: data[i].username,
              type: "string",
            },
            {
              id: 3,
              name: data[i].phone,
              type: "string",
            },
            {
              id: 4,
              name: data[i].role.name,
              type: "string",
            },
            {
              id: 5,
              name: data[i].departments
                .map((x) => {
                  return " " + x.name;
                })
                .toString(),
              type: "string",
            },
            {
              id: 6,
              name: deleted ? data[i].id : null,
              type: "restoreEmployee",
            },
          ],
        });
      }
    }
    return this.body;
  }
}
