<template>
  <div>
    <loader v-if="loading"></loader>
    <table-component
      v-else
      :items="items"
      :edit="true"
      :delet="true"
      @delete-button="deleteButton"
      @restore="restoreEmployee($event)"
    >
      <div class="btns">
        <v-btn
          text
          :color="!deletedEmployee ? '#5d4bd0' : null"
          @click="deletedEmployee = false"
          >Действующие<v-icon color="#5d4bd0" class="ml-2"
            >mdi-check-circle</v-icon
          ></v-btn
        >
        <v-btn
          text
          :color="deletedEmployee ? '#5d4bd0' : null"
          @click="deletedEmployee = true"
          >Удаленные<v-icon color="error" class="ml-7">mdi-delete</v-icon>
        </v-btn>
      </div>
    </table-component>
  </div>
</template>
<script>
import Loader from "@/components/Loader";
import TableComponent from "@/components/TableComponent";
import employee from "@/model/employee.js";
import ShowMessage from "@/Functions/message";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    TableComponent,
    Loader,
  },
  data() {
    return {
      loading: true,
      depId: 0,
      deletedEmployee: false,
      items: {
        head: [
          { id: 1, name: "ID" },
          { id: 2, name: "Имя" },
          { id: 3, name: "Телефон" },
          { id: 4, name: "Роль" },
          { id: 5, name: "Филиал" },
          { id: 6, name: null },
        ],
        body: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      EMPLOYEE: "Employee/STATE",
    }),
  },
  watch: {
    deletedEmployee() {
      this.setBody();
    },
  },
  async created() {
    this.checkPower();
    await this.setBody();
  },
  methods: {
    ...mapActions({
      employeeCity: "Employee/GET_CITY_EMPLOYEE",
      deleteEmployee: "Employee/DELETE_EMPLOYEE",
      RESTORE_EMPLOYEE: "Employee/RESTORE_EMPLOYEE",
      GET_CITY_DELETED_EMPLOYEE: "Employee/GET_CITY_DELETED_EMPLOYEE",
    }),
    checkPower() {
      let state = this.$power.check(this.$route.meta.power);
      if (state === false) {
        this.$router.push("/");
      }
    },
    async setBody() {
      this.loading = true;
      if (this.deletedEmployee) {
        await this.GET_CITY_DELETED_EMPLOYEE(this.$root.city);
      } else {
        await this.employeeCity(this.$root.city);
      }
      let model = new employee();
      this.items.body = model.setBody(
        this.EMPLOYEE.cityEmployee,
        this.deletedEmployee
      );
      this.loading = false;
    },
    async deleteButton(item) {
      this.loading = true;
      let errors = [];
      for (let i = 0; i < item.length; i++) {
        let response = await this.deleteEmployee(item[i].id);
        if (response.type !== "success") {
          errors.push(item[i].id);
        }
      }
      if (errors.length !== 0) {
        ShowMessage(errors);
      } else {
        ShowMessage("Сотрудник удален", true);
        await this.setBody();
      }
      this.loading = false;
    },
    async restoreEmployee(id) {
      this.loading = true;
      let response = await this.RESTORE_EMPLOYEE(id);
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("Сотрудник восстановлен", true);
        await this.setBody();
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.btns {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
